import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to="/privacy" style={{ marginRight: '20px' }}>Privacy Policy</Link>
        <Link to="/terms" style={{ marginRight: '20px' }}>Terms and Conditions</Link>
        <Link to="/about-us">About Us</Link>
      </div>
    </footer>
  );
};

export default Footer;
