import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";


function Header() {
  return (
    <header className="header">
      <div className="logo">
        <span className="gold-text">Gold</span>
        <span className="gold-text">Bee</span>
      </div>
      <div className="menu">
        <ul className="menu-list">
          <li className="menu-item">
          <Link to="/gold" class="list">
            Digital Gold
          </Link>
          </li>
          <li className="menu-item"></li>
          <li className="menu-item"></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
