import React from 'react'
import Header from '../components/header/Header'
import MainTitle from '../components/maintitle/MainTitle'
import Brands from '../components/Brands/Brands'
import ReverseBrand from '../components/ReverseBrand/ReverseBrand'
import Footer from '../components/Footer/Footer'

const Homepage = () => {
  return (
    <div>
        <Header />
        <MainTitle />
        <Brands />
        <ReverseBrand />
        <Footer />
    </div>
  )
}

export default Homepage